exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mmemo-amitiza-js": () => import("./../../../src/pages/mmemo/Amitiza.js" /* webpackChunkName: "component---src-pages-mmemo-amitiza-js" */),
  "component---src-pages-mmemo-bmi-js": () => import("./../../../src/pages/mmemo/BMI.js" /* webpackChunkName: "component---src-pages-mmemo-bmi-js" */),
  "component---src-pages-mmemo-bsa-js": () => import("./../../../src/pages/mmemo/BSA.js" /* webpackChunkName: "component---src-pages-mmemo-bsa-js" */),
  "component---src-pages-mmemo-child-2-js": () => import("./../../../src/pages/mmemo/child2.js" /* webpackChunkName: "component---src-pages-mmemo-child-2-js" */),
  "component---src-pages-mmemo-child-js": () => import("./../../../src/pages/mmemo/child.js" /* webpackChunkName: "component---src-pages-mmemo-child-js" */),
  "component---src-pages-mmemo-cholebine-js": () => import("./../../../src/pages/mmemo/cholebine.js" /* webpackChunkName: "component---src-pages-mmemo-cholebine-js" */),
  "component---src-pages-mmemo-denovo-js": () => import("./../../../src/pages/mmemo/denovo.js" /* webpackChunkName: "component---src-pages-mmemo-denovo-js" */),
  "component---src-pages-mmemo-epley-js": () => import("./../../../src/pages/mmemo/Epley.js" /* webpackChunkName: "component---src-pages-mmemo-epley-js" */),
  "component---src-pages-mmemo-fib-4-2-js": () => import("./../../../src/pages/mmemo/fib4_2.js" /* webpackChunkName: "component---src-pages-mmemo-fib-4-2-js" */),
  "component---src-pages-mmemo-fib-4-3-js": () => import("./../../../src/pages/mmemo/fib4_3.js" /* webpackChunkName: "component---src-pages-mmemo-fib-4-3-js" */),
  "component---src-pages-mmemo-fib-4-4-js": () => import("./../../../src/pages/mmemo/fib4_4.js" /* webpackChunkName: "component---src-pages-mmemo-fib-4-4-js" */),
  "component---src-pages-mmemo-fib-4-5-js": () => import("./../../../src/pages/mmemo/fib4_5.js" /* webpackChunkName: "component---src-pages-mmemo-fib-4-5-js" */),
  "component---src-pages-mmemo-fib-4-js": () => import("./../../../src/pages/mmemo/fib4.js" /* webpackChunkName: "component---src-pages-mmemo-fib-4-js" */),
  "component---src-pages-mmemo-gamma-js": () => import("./../../../src/pages/mmemo/gamma.js" /* webpackChunkName: "component---src-pages-mmemo-gamma-js" */),
  "component---src-pages-mmemo-ibd-js": () => import("./../../../src/pages/mmemo/IBD.js" /* webpackChunkName: "component---src-pages-mmemo-ibd-js" */),
  "component---src-pages-mmemo-iribow-js": () => import("./../../../src/pages/mmemo/Iribow.js" /* webpackChunkName: "component---src-pages-mmemo-iribow-js" */),
  "component---src-pages-mmemo-ldl-js": () => import("./../../../src/pages/mmemo/LDL.js" /* webpackChunkName: "component---src-pages-mmemo-ldl-js" */),
  "component---src-pages-mmemo-linzess-js": () => import("./../../../src/pages/mmemo/Linzess.js" /* webpackChunkName: "component---src-pages-mmemo-linzess-js" */),
  "component---src-pages-mmemo-menu-js": () => import("./../../../src/pages/mmemoMenu.js" /* webpackChunkName: "component---src-pages-mmemo-menu-js" */),
  "component---src-pages-mmemo-polyphil-js": () => import("./../../../src/pages/mmemo/polyphil.js" /* webpackChunkName: "component---src-pages-mmemo-polyphil-js" */),
  "component---src-pages-mmemo-probio-js": () => import("./../../../src/pages/mmemo/probio.js" /* webpackChunkName: "component---src-pages-mmemo-probio-js" */),
  "component---src-pages-mmemo-uc-js": () => import("./../../../src/pages/mmemo/UC.js" /* webpackChunkName: "component---src-pages-mmemo-uc-js" */),
  "component---src-pages-second-js": () => import("./../../../src/pages/second.js" /* webpackChunkName: "component---src-pages-second-js" */)
}

